<template>
  <div class="suppliers-map" id="suppliersMap"></div>
</template>

<script>
// TODO: REFACTOR - Remove ID from element and use ref instead!

import { imagePath } from '@lib/imgproxy';
import mapStyle from 'apps/public/lib/google-maps-style.json';
import MarkerClusterer from '@google/markerclustererplus';

const dotMarker = (bg = '#37495e', r = 5) => {
  let encoded = window.btoa(`<svg fill="${bg}" width="${r * 2}" height="${r * 2}" viewBox="0 0 ${r * 2} ${
    r * 2
  }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <circle cx="${r}" cy="${r}" r="${r}" />
      </svg>`);
  return `data:image/svg+xml;base64,${encoded}`;
};

let map,
  icon,
  markers = [],
  markerClusterer,
  infoBox;

async function defaultMarkerContent({ item }) {
  const { data: supplier } = await this.$store.suppliers.getOverview(item.id);

  let content = [];
  if (supplier.footwearTypes) content.push(supplier.footwearTypes.map((ft) => ft.title).join(', '));
  if (supplier.processes) content.push(supplier.processes.map((p) => p.title).join(', '));

  let img = '';
  if (supplier.coverPhoto) img = imagePath(`images/${supplier.coverPhoto}`, 960, 250);

  const slug = supplier.slug;
  const title = supplier.title;

  return {
    content,
    img,
    link: {
      action: () => {
        this.$router.push({ name: 'supplier', params: { name: supplier.slug } });
      },
      text: 'View info',
    },
    slug,
    title,
  };
}

export default {
  props: {
    countries: {
      type: Array,
    },
    data: {
      type: Array,
    },
    markerContent: {
      type: Function,
      default: defaultMarkerContent,
    },
  },
  data() {
    return {};
  },
  methods: {
    initMap() {
      // Main options
      const options = {
        center: new google.maps.LatLng('39.399872', '-8.224454'), // Default center is Portugal
        zoom: 8,
        styles: mapStyle,
        disableDefaultUI: true,
        zoomControl: true,
        backgroundColor: '#e3eaf3',
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
          width: '32px',
        },
        fullscreenControl: true,
      };
      // Init map
      map = new google.maps.Map(document.getElementById('suppliersMap'), options);
      // Marker icon
      icon = {
        url: dotMarker(),
        anchor: new google.maps.Point(5, 5),
        scaledSize: new google.maps.Size(10, 10),
      };
      // Clusterer Styles
      const clustererStyles = [
        {
          width: 24,
          height: 24,
          url: dotMarker('#37495e', 12),
          className: 'suppliers-map__icon suppliers-map__icon--24',
        },
        {
          width: 32,
          height: 32,
          url: dotMarker('#37495e', 16),
          className: 'suppliers-map__icon suppliers-map__icon--32',
        },
        {
          width: 40,
          height: 40,
          url: dotMarker('#37495e', 20),
          className: 'suppliers-map__icon suppliers-map__icon--40',
        },
      ];
      // Clusters
      markerClusterer = new MarkerClusterer(map, markers, { styles: clustererStyles });
      // Set Markers
      this.setMarkers();

      // Listeners
      map.addListener('click', (e) => {
        if (infoBox) infoBox.close();
      });

      // Highlight selected countries
      this.highlightCountries();

      this.$emit('init');
    },
    setMarkers() {
      // Reset markers
      if (markers.length) {
        markerClusterer.clearMarkers();
        markers = [];
      }
      // Add markers
      this.data.forEach((item) => {
        let position = new google.maps.LatLng(item.latitude, item.longitude);
        this.addMarker(position, item);
      });
      markerClusterer.addMarkers(markers);
      // Bound
      if (markers.length) {
        let bounds = new google.maps.LatLngBounds();
        for (var i = 0; i < markers.length; i++) {
          bounds.extend(markers[i].getPosition());
        }
        map.fitBounds(bounds);
        // Max zoom
        if (map.getZoom() > 13) {
          map.setZoom(13);
        }
      }
    },
    addMarker(position, item) {
      let marker = new google.maps.Marker({
        position,
        map,
        optimized: false,
        icon,
      });
      markers.push(marker);
      // Click event listener
      google.maps.event.addListener(marker, 'click', () => {
        if (infoBox) infoBox.close();

        this.markerContent({ item }).then((data) => {
          infoBox = new InfoBox({
            boxClass: 'map-popup',
            closeBoxURL: '',
            pixelOffset: new google.maps.Size(data.img ? -160 : -115, 0),
            boxStyle: {
              width: data.img ? '320px' : '230px',
            },
            alignBottom: true,
            content: this.infoBoxTemplate(data),
          });
          infoBox.open(map, marker);
          infoBox.addListener('domready', () => {
            let infoBoxLink = document.querySelector('.map-popup__link');
            infoBoxLink.addEventListener('click', data.link.action);
          });
        });
      });
    },
    infoBoxTemplate({ content, img, link, title }) {
      img = img ? `<img class="map-popup__img" src="${img}">` : '';
      content = content
        .filter(Boolean)
        .map((c) => `<p class="map-popup__text">${c}</p>`)
        .join('');

      return `<div class="map-popup__container">
          ${img}
          <div class="map-popup__content">
            <div class="map-popup__title" title="${title}">${title}</div>
            ${content}
            <span class="map-popup__link">${link.text}</span>
          </div>
        </div>`;
    },
    highlightCountries() {
      for (const country of this.countries) {
        const codeAlpha3 = this.$store.countries.codeAlpha3FromName(country);

        this.$store.countries.loadGeoJSONForGMaps(map, codeAlpha3);

        map.data.setStyle({
          fillColor: '#3498DB',
          fillOpacity: 0.04,
          strokeWeight: 1,
          strokeOpacity: 1,
          strokeColor: '#3498DB',
        });
      }
    },
  },
  mounted() {
    // Gmaps needs to be loaded before GMaps InfoBox, since
    // InfoBox has a direct dependency on load on GMaps(google.maps.XXX)
    this.$store.countries
      .loadGMaps()
      .then(() => this.$store.countries.loadGMapsInfoBox())
      .then(() => {
        this.initMap();
      });
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.suppliers-map {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  button img {
    display: inline-block;
  }

  &__icon {
    font-family: Arial, sans-serif;
    font-weight: bold;
    color: $c-light;

    &--24 {
      line-height: math.div(2.4rem, 1.6);
      font-size: math.div(1.2rem, 1.6);
    }

    &--32 {
      line-height: math.div(3.2rem, 1.6);
      font-size: math.div(1.3rem, 1.6);
    }

    &--40 {
      line-height: math.div(4rem, 1.6);
      font-size: math.div(1.4rem, 1.6);
    }
  }
  /*
      width: 24,
            height: 24,
            url: dotMarker('#37495e', 12),
            fontFamily: 'Lato,sans-serif',
            textColor: 'white',
            textSize: 12,
            className: 'suppliers-map__icon--24'
    */
}
.map-popup {
  padding: math.div(1rem, 1.6);
  font-family: 'Lato', sans-serif;
  font-size: math.div(1.2rem, 1.6);
  color: $c-dark;
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: $c-light;
    box-shadow: 0 0 math.div(0.3rem, 1.6) math.div(0.3rem, 1.6) rgba($c-dark, 0.2);
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      margin-left: math.div(-0.5rem, 1.6);
      border-style: solid;
      border-width: math.div(0.4rem, 1.6) math.div(0.5rem, 1.6) 0 math.div(0.5rem, 1.6);
      border-color: $c-light transparent transparent transparent;
    }
  }
  &__img {
    display: block;
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-size: cover;
    background-position: center;
  }
  &__content {
    position: relative;
    width: 100%;
    padding: math.div(0.6rem, 1.6) math.div(1rem, 1.6);
    flex-grow: 1;
    & > * {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  &__title {
    font-weight: bold;
    font-size: math.div(1.4rem, 1.6);
  }
  &__text {
    margin: math.div(0.4rem, 1.6) 0;
  }
  &__link {
    display: inline-block;
    margin: math.div(0.5rem, 1.6) 0;
    border-bottom: 1px solid $c-blue;
    color: $c-blue;
    cursor: pointer;
    transition: border-color 0.5s ease;
    &:hover {
      border-color: transparent;
    }
  }
}
</style>
